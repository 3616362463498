import { createAction } from '@ngrx/store'
import { ProjectedFleetOrderOnDetails } from '../../../_core/models/fleet-order.models'
import { VehicleDetailsFindOptions } from './service-details.reducer'
import { GallonsAndPriceCsv } from '../../../_shared/modules/gallons-and-price/typings'
import { FleetFeedback } from '@boosterfuels/types/types-ts'
import { SelectFleetCreator } from '../../../_core/root-store/user/user.actions'
import { FuelingSuccessConfig } from '@fleet-customer/shared/fill-repartition-and-fueling-success'
import { FuelingSuccessDetailsTableData } from '../../../_shared/modules/fueling-success-details-table/typings'

export const serviceDetailsActions = {
  FIND_FLEET_ORDER: createAction(
    '[Service Details Page] Find Fleet Order',
    (fleetOrderId: string) => ({
      payload: { fleetOrderId },
    })
  ),

  FIND_FLEET_ORDER_SUCCESS: createAction(
    '[Fleet Order Api] Find Fleet Order Success',
    (
      fleetOrder: ProjectedFleetOrderOnDetails,
      locationDetails: any[] = [],
      serviceFeedback: FleetFeedback
    ) => ({ payload: { fleetOrder, locationDetails, serviceFeedback } })
  ),

  FIND_FLEET_ORDER_ERROR: createAction(
    '[Fleet Order Api] Find Fleet Order Error'
  ),

  SET_VEHICLE_DETAILS_FIND_OPTIONS: createAction(
    '[Service Details Page] Set Vehicle Details Find Options',
    (vehicleDetailsFindOptions: Partial<VehicleDetailsFindOptions>) => ({
      payload: vehicleDetailsFindOptions,
    })
  ),

  SET_VEHICLE_DETAILS_FIND_OPTIONS_SUCCESS: createAction(
    '[Service Details Effects] Set Vehicle Details Find Options Success',
    (vehicleDetailsFindOptions: Partial<VehicleDetailsFindOptions>) => ({
      payload: vehicleDetailsFindOptions,
    })
  ),

  EXPORT_VEHICLE_DETAILS_CSV: createAction(
    '[Service Details Page] Export Vehicle Details Csv'
  ),

  EXPORT_VEHICLE_DETAILS_CSV_SUCCESS: createAction(
    '[Service Details Effects] Export Vehicle Details Csv Success'
  ),

  EXPORT_GALLONS_AND_PRICE_CSV: createAction(
    '[Service Details Page] Export Gallons And Price Csv',
    (csv: GallonsAndPriceCsv) => ({ payload: csv })
  ),

  EXPORT_GALLONS_AND_PRICE_CSV_SUCCESS: createAction(
    '[Service Details Effects] Export Gallons And Price Csv Success'
  ),

  EXPORT_VEHICLES_SUMMARY_CSV: createAction(
    '[Service Details Page] Export Vehicles Summary Csv',
    (data: FuelingSuccessDetailsTableData, fleetAccountName: string) => ({
      payload: { fuelingSuccessTableData: data, fleetAccountName },
    })
  ),

  EXPORT_VEHICLES_SUMMARY_CSV_SUCCESS: createAction(
    '[Service Details Effects] Export Vehicles Summary Csv Success'
  ),

  TOGGLE_MAP: createAction('[Service Details Page] Toggle Map'),

  CREATE_MAP_FEATURE_FEEDBACK: createAction(
    '[Service Details Page] Create Map Feature Feedback',
    (fleetFeedback: Partial<FleetFeedback>) => ({ payload: fleetFeedback })
  ),

  CREATE_MAP_FEATURE_FEEDBACK_SUCCESS: createAction(
    '[FleetFeedback Api] Create Map Feature Feedback Success',
    (response: { fleetFeedback: FleetFeedback }) => ({
      payload: response.fleetFeedback,
    })
  ),

  CREATE_MAP_FEATURE_FEEDBACK_ERROR: createAction(
    '[FleetFeedback Api] Create Map Feature Feedback Error'
  ),

  SEND_SERVICE_FEEDBACK: createAction(
    '[Service Details Page] Send Service Feedback',
    (fleetFeedback: Partial<FleetFeedback>) => ({ payload: fleetFeedback })
  ),

  SEND_SERVICE_FEEDBACK_SUCCESS: createAction(
    '[FleetFeedback Api] Send Service Feedback Success',
    (response: { fleetFeedback: FleetFeedback }) => ({ payload: response })
  ),

  SEND_SERVICE_FEEDBACK_ERROR: createAction(
    '[FleetFeedback Api] Send Service Feedback Error'
  ),

  SELECT_FLEET: createAction(
    '[Service Details Page] Select Fleet',
    SelectFleetCreator
  ),

  SET_ORIGIN_FLEET: createAction('[Service Details Page] Set Origin Fleet IDs'),

  SET_FUELING_SUCCESS_CONFIG: createAction(
    '[Service Details Page] Set Fueling Success Config',
    (fuelingSuccessConfig: FuelingSuccessConfig) => ({
      payload: fuelingSuccessConfig,
    })
  ),

  LEAVE_PAGE: createAction('[Service Details Page] Leave Page'),
}
